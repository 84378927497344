<template>
  <div id="admin-home-statistics">
    <div class="title">Ana Sayfa</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-date-picker v-model="dateRange" :default-time="['3:00:00', '23:59:59']" class="statistics-date-range" type="daterange" format="dd.MM.yyyy" range-separator="-" start-placeholder="Başlangıç" prefix-icon="icon-calendar"
      end-placeholder="Bitiş"> </el-date-picker>
    <div class="allcard">
      <div class="card" v-for="(item, index) in chartInfo" :key="index">
        <div class="card-left">
          <div class="card-tag">{{ item.Title }}</div>
          <div class="card-desc">{{ item.Description }}</div>
          <el-row type="flex" class="row-bg" justify="space-between" align="middle">
            <el-col :span="12">
              <div class="card-total-number">{{ item.Count }}</div>
            </el-col>
            <el-col :span="12">
              <el-row type="flex" align="middle" class="row-bg" justify="end">
                <i class="icon-trade-up" :class="{ plus: item.IncreaseRate >= 0 ? true : false }"></i>
                <div class="card-percent" :class="{ plus: item.IncreaseRate >= 0 ? true : false }">{{ item.IncreaseRate }}</div>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="bar-container today-meet">
          <div class="one-day" v-for="(stat, index) in item.Statistics" :key="index">
            <div class="stick">
              <div class="bar" :class="{ active: stat.Today }" :style="{ height: stat.Percentage + 'px' }"></div>
            </div>
            <div class="text">{{ $moment(stat.CalculatedDateRangeMax).format("dddd")[0] }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="title second">Kullanıcı Grafiği</div>
    <div class="second-chart-area">
      <div class="area-1">
        <div id="appointment-line-chart"></div>
      </div>
    </div>
    <div class="title second">Görüşme İstatistikleri</div>
    <div class="second-chart-area">
      <div class="area-1">
        <div id="appointment-wave-chart"></div>
      </div>
    </div>

    <div class="first">
      <div class="f1">
        <div class="title">Cinsiyet İstatistikleri</div>
        <div class="area" id="PieChartOne"></div>
      </div>
      <div class="f1">
        <div class="title">Platform İstatistikleri</div>
        <div class="area" id="PieChartTwo"></div>
      </div>
    </div>
    <div class="second-stat">
      <div class="last">
        <div class="title">Danışman İstatistikleri</div>
        <div class="area-2">
          <ul class="doctor-statistics">
            <li v-for="(item, index) in statistics.DoctorAppointments" :key="index">
              <img :src="item.Image" :alt="item.Title" />
              <div class="user-info">
                <div class="name">{{ item.Title }}</div>
                <div class="specialist"></div>
              </div>
              <div class="total">Katıldığı Görüşme Sayısı: {{ item.Count }} Görüşme</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="last">
        <div class="title">Hemşire İstatistikleri</div>
        <div class="area-2">
          <!--    <ul class="doctor-statistics">
            <li v-for="(item, index) in statistics.DoctorAppointments" :key="index">
              <img :src="item.Image" :alt="item.Title" />
              <div class="user-info">
                <div class="name">{{ item.Title }}</div>
                <div class="specialist"></div>
              </div>
              <div class="total">Katıldığı Görüşme Sayısı: {{ item.Count }} Görüşme</div>
            </li>
          </ul>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import locales from "@amcharts/amcharts5/locales/tr_TR";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";

export default {
  name: "Home",
  data() {
    return {
      dateRange: [],
      chartInfo: [],
      allStatistics: {},
      appointmentChartData: [],
      chartRoot: [],
    };
  },
  beforeMount() {
    this.dateRange[0] = this.$moment().add(-1, "months").toISOString();
    this.dateRange[1] = this.$moment().add("24", "hours").toISOString();
    this.appointmentStatistics();
    this.getStatistics();
  },
  mounted() { },
  methods: {
    async getStatistics() {
      await this.$store
        .dispatch("getAdminStatistics", {
          MinDate: this.dateRange[0],
          MaxDate: this.dateRange[1],
        })
        .then(async () => {
          await this.createLineChart();
          await this.createWaveChart();
          await this.createPieChartOne();
          await this.createPieChartTwo();
        });
    },
    async appointmentStatistics() {
      var stat = await this.$client.post("/Statistics/AppointmentStatistics", {});
      if (stat.data.HasError) {
        this.$message.error(stat.data.Message);
      } else {
        this.chartInfo = stat.data.Data.Statistics;
      }
    },
    async createLineChart() {
      var datas = this.statistics.Users;
      var root = am5.Root.new("appointment-line-chart");
      this.chartRoot[0] = root;
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );

      var data = [];
      datas.forEach((element) => {
        data.push({
          year: element.DayStr,
          europe: element.Count,
        });
      });
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "year",
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xAxis.data.setAll(data);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      function makeSeries(name, fieldName) {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: name,
            stacked: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: "year",
          })
        );

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(10),
        });
        series.data.setAll(data);
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });
        legend.data.push(series);
      }

      makeSeries("Yeni Kullanıcı", "europe");
      chart.appear(1000, 100);
    },
    async createWaveChart() {
      var datas = JSON.parse(JSON.stringify(this.statistics.Appointments));
      var root = am5.Root.new("appointment-wave-chart");
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
        })
      );
      root.locale = locales;
      chart.get("colors").set("step", 3);
      var data = [];
      datas.forEach((element) => {
        data.push({
          date: new Date(element.Day).getTime(),
          value1: element.Completed,
          value2: element.Cancelled,
        });
      });
      var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);
      var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.3,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value1",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueX}: {valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.get("tooltip").get("background").set("fillOpacity", 0.5);

      var series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Series 2",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value2",
          valueXField: "date",
        })
      );
      series2.strokes.template.setAll({
        strokeDasharray: [2, 2],
        strokeWidth: 2,
      });
      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"],
      });

      series.data.setAll(data);
      series2.data.setAll(data);
      series.appear(1000);
      series2.appear(1000);
      chart.appear(1000, 100);
      this.chartRoot[1] = root;
    },

    async createPieChartOne() {
      var datas = this.statistics.GenderRatio;

      var root = am5.Root.new("PieChartOne");

      const responsive = am5themes_Responsive.new(root);
      responsive.addRule({
        name: "AxisRendererY",
        relevant: function (width) {
          return width < 1400;
        },
        settings: {
          inside: true,
        },
      });

      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          endAngle: 270,
        })
      );

      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: "value",
          categoryField: "category",
          endAngle: 270,
        })
      );

      series.states.create("hidden", {
        endAngle: -90,
      });
      series.data.setAll([
        {
          category: "Kadın",
          value: datas.Female,
        },
        {
          category: "Erkek",
          value: datas.Male,
        },

        {
          category: "Tanımsız",
          value: datas.Undefined,
        },
      ]);
      this.chartRoot[2] = root;
      series.appear(1000, 100);
    },
    async createPieChartTwo() {
      var datas = this.statistics.PlatformRatio;

      var root = am5.Root.new("PieChartTwo");

      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      var chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          endAngle: 270,
        })
      );

      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: "value",
          categoryField: "category",
          endAngle: 270,
        })
      );

      series.states.create("hidden", {
        endAngle: -90,
      });
      series.data.setAll([
        {
          category: "Android",
          value: datas.Android,
        },
        {
          category: "IOS",
          value: datas.IOS,
        },

        {
          category: "Web",
          value: 10,
        },
      ]);
      this.chartRoot[3] = root;
      series.appear(1000, 100);
    },
  },
  computed: {
    statistics() {
      return this.$store.getters.getAdminStatistics;
    },
  },
  watch: {
    dateRange: async function () {
      this.$nextTick(async () => {
        await this.chartRoot.forEach((element) => element.dispose());
      });
      await this.getStatistics();
    },
  },
};
</script>

<style lang="less"></style>
